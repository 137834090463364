import * as fromOthers from '../actions/others.actions';

export interface OthersState{

    showPanel:boolean | null;
    nuvalidAccess:boolean | null;
    scrollEnterpriseLayout:any | null

}

const initialState: OthersState={
    showPanel:false,
    nuvalidAccess:false,
    scrollEnterpriseLayout:null
    
};

export function othersReducer (state=initialState,action:fromOthers.othersActions): OthersState{
    switch (action.type) {
        case fromOthers.SET_SHOW_PANEL:
            return{
                ...state,
                showPanel:action.payload
                
            };
        case fromOthers.SET_NUVALID_ACCESS:
            return{
                ...state,
                nuvalidAccess:action.payload
                
            };
        case fromOthers.SET_SCROLL_ENTERPRISE:
            return{
                ...state,
                scrollEnterpriseLayout:action.payload
                
            };
        
        default:
            return state;
    }
}

