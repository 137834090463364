
import * as fromNewSchedule from '../actions/new-schedule.actions';

export interface NewScheduleState{
    scheduleInfo: any,
    employeeInfo: any;
    attentionDays: any;
    dateFrom: any;
    dateUntil: any;
    turnsFrecuency: any;
}

const initialState: NewScheduleState={
    scheduleInfo: null,
    employeeInfo: null,
    attentionDays: null,
    dateFrom: null,
    dateUntil: null,
    turnsFrecuency: null,
};

export function newScheduleReducer (state=initialState,action:fromNewSchedule.newScheduleActions): NewScheduleState{
    switch (action.type) {
        case fromNewSchedule.SET_NEW_SCHEDULE:
            return{
                ...state,
                scheduleInfo: {... action.firstInfo},
            };
        case fromNewSchedule.UNSET_NEW_SCHEDULE:
            return {
                ...state,
                scheduleInfo: null,
            };

        case fromNewSchedule.SET_EMPLOYEE_ASSOCIATED:
            return{
                ...state,
                employeeInfo: {... action.employeeData},
            };
        case fromNewSchedule.UNSET_EMPLOYEE_ASSOCIATED:
            return {
                ...state,
                employeeInfo: null,
            };

        case fromNewSchedule.SET_ATTENTION_DAYS:
            return{
                ...state,
                attentionDays: action.attentionDaysData,
            };
        case fromNewSchedule.UNSET_ATTENTION_DAYS:
            return {
                ...state,
                attentionDays: null,
            };

        case fromNewSchedule.SET_DATE_FROM:
            return{
                ...state,
                dateFrom: action.dateFrom,
            };

        case fromNewSchedule.UNSET_DATE_FROM:
            return {
                ...state,
                dateFrom: null,
            };

        case fromNewSchedule.SET_DATE_UNTIL:
            return{
                ...state,
                dateUntil: action.dateUntil,
            };
            
        case fromNewSchedule.UNSET_DATE_UNTIL:
            return {
                ...state,
                dateUntil: null,
            };

        case fromNewSchedule.SET_TURNS_FRECUENCY:
            return{
                ...state,
                turnsFrecuency: action.turnsFrecuency,
            };
            
        case fromNewSchedule.UNSET_TURNS_FRECUENCY:
            return {
                ...state,
                turnsFrecuency: null,
            };
    
        case fromNewSchedule.UNSET_ALL_SCHEDULE:
            return {
                scheduleInfo: null,
                employeeInfo: null,
                attentionDays: null,
                dateFrom: null,
                dateUntil: null,
                turnsFrecuency: null
            };
        
        default:
            return state;
    }
}

