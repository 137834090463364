import { Action } from "@ngrx/store";

export const SET_AVAILABILITY_TO_CHANGE = '[AvailablilityFlag] Set Availability to change step';
export const UNSET_AVAILABILITY_TO_CHANGE = '[AvailablilityFlag] Unset Availability to change step';

export class SetAvailabilityToChangeAction implements Action {
    readonly type=SET_AVAILABILITY_TO_CHANGE;
    constructor( public availableToChange:any){
    }
}

export class UnsetAvailabilityToChangeAction implements Action {
    readonly type = UNSET_AVAILABILITY_TO_CHANGE;
}


export type availabilityFlagActions =   SetAvailabilityToChangeAction   |
                                        UnsetAvailabilityToChangeAction;
