import { Injectable } from '@angular/core';

// Store
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import * as storeActions from '../store/actions';


@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor(private _store: Store<AppState>) { }

  setSession(key: string, data: any) {
    try {
      sessionStorage.setItem(key, data);
    } catch (e) {
      console.log('Error en storage: ', e);
    }
  }

  getSession(key: string) {

    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      console.log('Error en storage: ', e);
      return;
    }
  }

  setCurrentUser(currentUser: any) {
    this.removeCurrentUser();
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getCurrentUser(): any{
    let currentUserResponse:any;
    let currentUser = sessionStorage.getItem('currentUser');
    if (currentUser!= null) {
      currentUserResponse = JSON.parse(currentUser);
    }
    return currentUserResponse;
  }

  removeCurrentUser() {
    sessionStorage.removeItem('currentUser');
  }

  setToken(filters: any) {
    this.removeToken();
    sessionStorage.setItem('token', JSON.stringify(filters));
  }

  getToken(): any{
    let tokenResponse:any;
    let token = sessionStorage.getItem('token');
    if (token!= null) {
      tokenResponse = JSON.parse(token);
    }
    return tokenResponse;
  }

  removeToken() {
    sessionStorage.removeItem('token');
  }

  setRefreshToken(filters: any) {
    this.removeRefreshToken();
    sessionStorage.setItem('refreshToken', JSON.stringify(filters));
  }

  getRefreshToken(): any{
    let refreshTokenResponse:any;
    let refreshToken = sessionStorage.getItem('refreshToken');
    if (refreshToken!= null) {
      refreshTokenResponse = JSON.parse(refreshToken);
    }
    return refreshTokenResponse;
  }

  removeRefreshToken() {
    sessionStorage.removeItem('refreshToken');
  }

  setTurnFiltersCreated(filters: any) {
    this.removeTurnFiltersCreated();
    sessionStorage.setItem('turnFiltersCreated', JSON.stringify(filters));
  }

  getTurnFiltersCreated(): any{
    let turnFiltersCreatedResponse:any;
    let turnFiltersCreated = sessionStorage.getItem('turnFiltersCreated');
    if (turnFiltersCreated!= null) {
      turnFiltersCreatedResponse = JSON.parse(turnFiltersCreated);
    }
    return turnFiltersCreatedResponse;
  }

  removeTurnFiltersCreated() {
    sessionStorage.removeItem('turnFiltersCreated');
  }

  setReassignTurn(reassignTurn: any) {
    this.removeReassignTurn();
    sessionStorage.setItem('reassignTurn', JSON.stringify(reassignTurn));
  }

  getReassignTurn(): any{
    let reassignTurnResponse:any;
    let reassignTurn = sessionStorage.getItem('reassignTurn');
    if (reassignTurn!= null) {
      reassignTurnResponse = JSON.parse(reassignTurn);
    }
    return reassignTurnResponse;
  }

  removeReassignTurn() {
    sessionStorage.removeItem('reassignTurn');
  }

  //CLIENT-APP
  setInstitutionsFound(institutionsFound: any) {
    this.removeInstitutionsFound();
    sessionStorage.setItem('institutionsFound', JSON.stringify(institutionsFound));
  }

  getInstitutionsFound(): any{
    let institutionsFoundResponse:any;
    let institutionsFound = sessionStorage.getItem('institutionsFound');
    if (institutionsFound!= null) {
      institutionsFoundResponse = JSON.parse(institutionsFound);
    }
    return institutionsFoundResponse;
  }

  removeInstitutionsFound() {
    sessionStorage.removeItem('institutionsFound');
  }

  //CLIENT-APP
  setInstitutionChosen(institutionChosen: any) {
    this.removeInstitutionChosen();
    sessionStorage.setItem('institutionChosen', JSON.stringify(institutionChosen));
  }

  getInstitutionChosen(): any{
    let institutionChosenResponse:any;
    let institutionChosen = sessionStorage.getItem('institutionChosen');
    if (institutionChosen!= null) {
      institutionChosenResponse = JSON.parse(institutionChosen);
    }
    return institutionChosenResponse;
  }

  removeInstitutionChosen() {
    sessionStorage.removeItem('institutionChosen');
  }

  setClientTurnsFilters(turnFilters: any) {
    this.removeClientTurnsFilters();
    sessionStorage.setItem('turnFilters', JSON.stringify(turnFilters));
  }

  getClientTurnFilters(): any{
    let turnFiltersResponse:any;
    let turnFilters = sessionStorage.getItem('turnFilters');
    if (turnFilters!= null) {
      turnFiltersResponse = JSON.parse(turnFilters);
    }
    return turnFiltersResponse;
  }

  removeClientTurnsFilters() {
    sessionStorage.removeItem('turnFilters');
  }


  //hidratate store (call app.component)
  hidratateStore(){

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('currentUser')){
      this._store.dispatch( new storeActions.SetUserAction(
          this.getCurrentUser()
      ));
    }

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('token')){
      this._store.dispatch(new storeActions.SetTokenAction(
        this.getToken()
      ))
    }

    //refresh token persist
    if(this.getSession('refreshToken')){
        this._store.dispatch(new storeActions.SetRefreshTokenAction(
          this.getRefreshToken()
        ))
      }

    //turnFiltersCreated data persist
    if(this.getSession('turnFiltersCreated')){
      this._store.dispatch(new storeActions.SetCreatedTurnFiltersAction(
        this.getTurnFiltersCreated()
      ))
    }

     //reassignTurn data persist
    if(this.getSession('reassignTurn')){
      this._store.dispatch(new storeActions.SetReassignTurnAction(
        this.getReassignTurn()
      ))
    }


    //CLIENT-APP
    if(this.getSession('institutionsFound')){
     this._store.dispatch( new storeActions.SetFindClientTurnInstitutionsAction(
         this.getInstitutionsFound()
     ));
    }

    //CLIENT-APP
    if(this.getSession('turnFilters')){
     this._store.dispatch( new storeActions.SetFindClientTurnFiltersAction(
         this.getClientTurnFilters()
     ));
    }

  }

  //clean session storage
  remove_sesionStorage(){
    this.removeCurrentUser();
    this.removeToken();
    this.removeRefreshToken();

    this.removeTurnFiltersCreated()
    this.removeReassignTurn()

    this.removeInstitutionsFound()
    this.removeClientTurnsFilters()

  }
}
