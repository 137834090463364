
import * as fromNewSchedule from '../actions/clear-searcher-flag.actions';

export interface AvailibilityToClearState{
    availableToClear: boolean
}

const initialState: AvailibilityToClearState={
    availableToClear: false
};

export function availabilityToClearReducer (state=initialState,action:fromNewSchedule.availabilityToClearActions): AvailibilityToClearState{
    switch (action.type) {        
        case fromNewSchedule.SET_AVAILABILITY_TO_CLEAR:
            return{
                ...state,
                availableToClear: action.availableToClear,
            };

        case fromNewSchedule.UNSET_AVAILABILITY_TO_CLEAR:
            return {
                ...state,
                availableToClear: true,
            };
        
        default:
            return state;
    }
}

