import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { of, switchMap } from 'rxjs';

export const NoProviderGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).isProvider().pipe(
        switchMap((providerAuthenticated) =>
        {
            // If the user is not a client...
            if ( providerAuthenticated == true )
            {
                console.log('it is a user');
                
                // Redirect to the sign-in page 
                const urlTree = router.parseUrl(`sign-in`);
                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};
