import { Action } from "@ngrx/store";
import { AssignedTurn } from "../models/assigned-turn.model";


export const SET_ASSIGNED_TURNS_LIST = '[Assigned turns] Set turns list';
export const UNSET_ASSIGNED_TURNS_LIST = '[Assigned turns] Unset turns list';

export const SET_ASSIGNED_SELECTED_TURN = '[Assigned turns] Set assigned turn';
export const UNSET_ASSIGNED_SELECTED_TURN = '[Assigned turns] Unset assigned turn';

export const SET_ASSIGNED_TURN_FILTERS = '[Assigned turns] Set Assigned turn filters';
export const UNSET_ASSIGNED_TURN_FILTERS = '[Assigned turns] Unset Assigned turn filters';

export class SetAssignedTurnsListAction implements Action {
    readonly type=SET_ASSIGNED_TURNS_LIST;
    constructor( public payload:any){
    }
}

export class UnSetAssignedTurnsListAction implements Action {
    readonly type=UNSET_ASSIGNED_TURNS_LIST;
    
}

export class SetAssignedSelectedTurnAction implements Action {
    readonly type=SET_ASSIGNED_SELECTED_TURN;
    constructor( public payload:any){
    }
}

export class UnSetAssignedSelectedTurnAction implements Action {
    readonly type=UNSET_ASSIGNED_SELECTED_TURN;
    
}

export class SetAssignedTurnFiltersAction implements Action {
    readonly type=SET_ASSIGNED_TURN_FILTERS;
    constructor( public payload:any){
    }
}

export class UnSetAssignedTurnFiltersAction implements Action {
    readonly type=UNSET_ASSIGNED_TURN_FILTERS;
    
}



export type assignedTurnsActions=    SetAssignedTurnsListAction             |
                                            UnSetAssignedTurnsListAction    |
                                            SetAssignedSelectedTurnAction   |
                                            UnSetAssignedSelectedTurnAction |
                                            SetAssignedTurnFiltersAction    |
                                            UnSetAssignedTurnFiltersAction
