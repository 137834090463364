/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'turnos',
        title   : 'Turnos',
        subtitle: 'Gestión y coordinación de turnos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [

            {
                id   : 'turnos.turnos-asignados',
                title: 'Turnos asignados',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/admin/turnos/turnos-asignados',
            },
            {
                id   : 'turnos.dar-turnos',
                title: 'Dar turnos',
                type : 'basic',
                icon: 'heroicons_outline:plus-circle',
                link : '/admin/turnos/dar-turnos',
            },

        ],
    },
    {
        id      : 'schedule',
        title   : 'Agendas',
        subtitle: 'Coordinación y creación de agendas',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'schedule.new-schedule',
                title: 'Nueva agenda',
                type : 'basic',
                icon : 'heroicons_outline:document-plus',
                link : '/admin/schedule/new-schedule',
            },
            {
                id   : 'schedule.update-schedule',
                title: 'Actualizar agenda',
                type : 'basic',
                icon : 'heroicons_outline:document-arrow-up',
                link : '/admin/schedule/update-schedule',
            },
        ],
    },
    {
        id      : 'administration',
        title   : 'Administración',
        subtitle: 'Administración de empleados/profesionales y roles',
        type    : 'group',
        icon    : 'heroicons_outline:user-group',
        children: [
            {
                id   : 'administration.users',
                title: 'Empleados/profesionales y roles',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/admin/administration/users',
            },
        ],
    },
];

export const defaultProviderNavigation: FuseNavigationItem[] = [
    {
        id      : 'provider-turns',
        title   : 'Turnos',
        subtitle: 'Gestión y coordinación de turnos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'provider-turns.turnos-asignados',
                title: 'Turnos asignados',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/admin/provider/turnos-propios',
            },
        ],
    },
    {
        id      : 'own-schedules',
        title   : 'Agendas',
        subtitle: 'Administración de agendas',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'own-schedules.update-schedule',
                title: 'Actualizar agenda',
                type : 'basic',
                icon : 'heroicons_outline:document-arrow-up',
                link : '/admin/provider/schedules',
            },
        ],
    },
    // {
    //     id      : 'provider-admin',
    //     title   : 'Administración',
    //     subtitle: 'Administración de empleados/profesionales y roles',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:user-group',
    //     children: [
    //         {
    //             id   : 'administration.users',
    //             title: 'Empleados/profesionales y roles',
    //             type : 'basic',
    //             icon : 'heroicons_outline:user-group',
    //             link : '/admin/administration/users',
    //         },
    //     ],
    // },
];


export const horizontalProviderNavigation: FuseNavigationItem[] = [
    {
        id      : 'provider-turns',
        title   : 'Turnos',
        type    : 'group',
        icon    : 'heroicons_outline:calendar-days',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'own-schedules',
        title   : 'Agendas',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    // {
    //     id      : 'provider-admin',
    //     title   : 'Administración',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:user-group',
    //     children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
];

export const defaultAdminNavigation: FuseNavigationItem[] = [
    {
        id      : 'turnos',
        title   : 'Turnos',
        subtitle: 'Gestión y coordinación de turnos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [

            {
                id   : 'turnos.turnos-asignados',
                title: 'Turnos asignados',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/admin/turnos/turnos-asignados',
            },
            {
                id   : 'turnos.dar-turnos',
                title: 'Dar turnos',
                type : 'basic',
                icon: 'heroicons_outline:plus-circle',
                link : '/admin/turnos/dar-turnos',
            },

        ],
    },
    {
        id      : 'schedule',
        title   : 'Agendas',
        subtitle: 'Coordinación y creación de agendas',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'schedule.new-schedule',
                title: 'Nueva agenda',
                type : 'basic',
                icon : 'heroicons_outline:document-plus',
                link : '/admin/schedule/new-schedule',
            },
            {
                id   : 'schedule.update-schedule',
                title: 'Actualizar agenda',
                type : 'basic',
                icon : 'heroicons_outline:document-arrow-up',
                link : '/admin/schedule/update-schedule',
            },
        ],
    },
    {
        id      : 'administration',
        title   : 'Administración',
        subtitle: 'Administración de empleados/profesionales y roles',
        type    : 'group',
        icon    : 'heroicons_outline:user-group',
        children: [
            {
                id   : 'administration.users',
                title: 'Empleados/profesionales y roles',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/admin/administration/users',
            },
        ],
    },
    {
        id      : 'docs',
        title   : 'Documentos',
        subtitle: 'Documentos de ayuda para el usuario',
        type    : 'group',
        icon    : 'heroicons_outline:user-group',
        children: [
            {
                id   : 'docs.guides',
                title: 'Guías',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/admin/docs/guias',
            },
        ],
    },
    // {
    //     id      : 'institutions_admin',
    //     title   : 'Institución',
    //     subtitle: 'Administración de instituciones',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:building-storefront',
    //     children: [
    //         {
    //             id   : 'institution.new-institution',
    //             title: 'Nueva institución',
    //             type : 'basic',
    //             icon : 'heroicons_outline:building-storefront',
    //             link : '/admin/institution/new-institution',
    //         }
    //     ],
    // },
];

export const defaultUserNavigation: FuseNavigationItem[] = [
    {
        id      : 'turnos-cliente',
        title   : 'Nuevo turno',
        subtitle: 'Gestión y coordinación de turnos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'turnos.dar-turnos',
                title: 'Reservar un turno',
                type : 'basic',
                icon: 'heroicons_outline:plus-circle',
                link : '/admin/client/find-turn',
            },
        ],
    },
    {
        id      : 'mis-turnos',
        title   : 'Mis turnos',
        subtitle: 'Historial de turnos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'turnos.turnos-asignados',
                title: 'Mis turnos',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/admin/turns/my-turns',
            },
        ],
    }
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboardssss',
        tooltip : 'Dashboards',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'turnos',
        title   : 'Turnos',
        tooltip : 'Turnos',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },

    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        tooltip : 'UI',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation',
        tooltip : 'Navigation',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'turnos',
        title   : 'TURNOS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'User Interface',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation Features',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'turnos',
        title   : 'Turnos',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    // {
    //     id      : 'pages',
    //     title   : 'Pages',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:document-duplicate',
    //     children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id      : 'user-interface',
    //     title   : 'UI',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:rectangle-stack',
    //     children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id      : 'navigation-features',
    //     title   : 'Misc',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:bars-3',
    //     children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
];

export const horizontalUserNavigation: FuseNavigationItem[] = [
    {
        id      : 'turnos-cliente',
        title   : 'Nuevo turno',
        type    : 'basic',
        icon    : 'heroicons_outline:plus-circle',
        link    : '/admin/client/find-turn',
    },
    {
        id      : 'mis-turnos',
        title   : 'Mis turnos',
        type    : 'basic',
        icon    : 'heroicons_outline:calendar-days',
        link    : '/admin/turns/my-turns',
    }
];
