import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthService } from 'app/services/auth.service';
import { SetRefreshTokenAction, SetTokenAction } from 'app/store/actions';
import { AppState } from 'app/store/app.reducer';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';

interface refreshTokenResponse {
    success: boolean,
    msg: string,
    data: {
        token: string,
        refreshToken: string
    }
}

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const tokenInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const authService = inject(AuthService);
    const store = inject(Store<AppState>)
    const router = inject(Router)
    // Clone the request object
    let newReq = req.clone();
    let refreshTokenInProgress = false;
    let accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "403 Expired token" responses
            if ( error instanceof HttpErrorResponse && error.status === 403)
            {
                if (!refreshTokenInProgress) {

                    refreshTokenInProgress = true;
                    accessTokenSubject.next(null);
                    return authService.refreshTokenMethod().pipe(

                        switchMap((authResponse: refreshTokenResponse) => {
                         // console.log('entra en interceptor',authResponse);
                            refreshTokenInProgress = false;
                            accessTokenSubject.next(authResponse.data.token);

                            //set user store
                            // const newUser:any= new CurrentUserModel(dataUser,authResponse.tokenId,authResponse.refreshToken);
                            store.dispatch( new SetRefreshTokenAction(authResponse.data.refreshToken));
                            store.dispatch( new SetTokenAction(authResponse.data.token));

                            req = req.clone({
                                setHeaders: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${authResponse.data.token}`
                                }
                            });
                            return next(req);
                        }),
                         catchError((e: HttpErrorResponse) => {
                          if (e instanceof HttpErrorResponse && e.status === 403) {
                            // console.log('catch error interceptor logout',e);

                            refreshTokenInProgress = false;
                            store.select('others').subscribe(data=>{
                                if(data.nuvalidAccess) {
                                    authService.singError();
                                } else {
                                    router.navigate(['/sign-out']);
                                }
                            })
                          }
                          return throwError(e);
                        })
                    )
                  } else{
                    return accessTokenSubject.pipe(
                      filter(accessToken => accessToken !== null),
                      take(1),
                      switchMap(token => {
                          req = req.clone({
                            setHeaders: {
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`
                            }
                          });
                          return next(req);
                      }));
                  }
            }
            return throwError(error);
        }),
    );
};
