import { Action } from "@ngrx/store";

export const SET_SPECIALTIES_CHOSEN = '[Specialties] Set Specialties Chosen';
export const UNSET_SPECIALTIES_CHOSEN = '[Specialties] Unset Specialties Chosen';

export class SetSpecialtiesChosenAction implements Action {
    readonly type=SET_SPECIALTIES_CHOSEN;
    constructor( public specialties: any){
    }
}

export class UnsetSpecialtiesChosenAction implements Action {
    readonly type = UNSET_SPECIALTIES_CHOSEN;
}

export type specialtiesActions= SetSpecialtiesChosenAction    |
                                UnsetSpecialtiesChosenAction  ;
