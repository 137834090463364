
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
//import * as models from './models/store.models'

import * as fromReducer from './reducers/';

export interface AppState{
    auth: fromReducer.AuthState;
    assignedTurns: fromReducer.AssignedTurnsState;
    createdTurns: fromReducer.CreatedTurnsState;
    newSchedule: fromReducer.NewScheduleState,
    updateSchedule: fromReducer.UpdateScheduleState,
    users:fromReducer.UsersState;
    client:fromReducer.ClientState;
    stepInfo:fromReducer.StepOrderState;
    availableFlag:fromReducer.AvailibilityFlagState;
    clearSearcherFlag:fromReducer.AvailibilityToClearState;
    funders: fromReducer.FundersState;
    specialties: fromReducer.SpecialtyState;
    others: fromReducer.OthersState;
    findClientTurn: fromReducer.FindClientTurnState,
    mapData: fromReducer.MapDataState
}

export const appReducers:ActionReducerMap<any,any>= { 
    auth: fromReducer.authReducer,
    newSchedule: fromReducer.newScheduleReducer,
    updateSchedule: fromReducer.updateScheduleReducer,
    assignedTurns: fromReducer.assignedTurnsReducer,
    createdTurns: fromReducer.createdTurnsReducer,
    users: fromReducer.usersReducer,
    client: fromReducer.clientsReducer,
    stepInfo: fromReducer.stepOrderReducer,
    availableFlag:fromReducer.availabilityFlagReducer,
    clearSearcherFlag:fromReducer.availabilityToClearReducer,
    funders: fromReducer.fundersReducer,
    specialties: fromReducer.specialtyReducer,
    others: fromReducer.othersReducer,
    findClientTurn:fromReducer.findClientTurnReducer,
    mapData:fromReducer.mapDataReducer
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function(state: AppState, action: Action): AppState {
        if (action.type === 'RESET_APP') {
          console.log('ENTRA CLEAR ')
          state = undefined;
      }
      return reducer(state, action);
    };
  }
  export const metaReducers: MetaReducer<AppState>[] = [clearState];