import { Action } from "@ngrx/store";

export const SET_USER = '[Auth] Set User';
export const UNSET_USER = '[Auth] Unset User';
export const SET_TOKEN = '[Auth] Set Token';
export const UNSET_TOKEN = '[Auth] Unset Token';
export const SET_REFRESH_TOKEN = '[Auth] Set RefreshToken';
export const UNSET_REFRESH_TOKEN = '[Auth] Unset RefreshToken';
export const SET_INSTITUTIONS = '[Auth] Set Institutions';
export const UNSET_INSTITUTIONS = '[Auth] Unset Institutions';
export const SET_INSTITUTION_CHOSEN = '[Auth] Set institution chosen';
export const UNSET_INSTITUTION_CHOSEN = '[Auth] Unset institution chosen';
export const UNSET_ALL = '[Auth] Unset All Auth Data';


export class SetUserAction implements Action {
    readonly type=SET_USER;
    constructor( public user:any){
    }
}

export class UnsetUserAction implements Action {
    readonly type = UNSET_USER;
}

export class SetTokenAction implements Action {
    readonly type=SET_TOKEN;
    constructor( public token:string){
    }
}

export class UnsetTokenAction implements Action {
    readonly type=UNSET_TOKEN;
    constructor(){
    }
}

export class SetRefreshTokenAction implements Action {
    readonly type=SET_REFRESH_TOKEN;
    constructor( public refreshToken:string){
    }
}

export class UnsetRefreshTokenAction implements Action {
    readonly type=UNSET_REFRESH_TOKEN;
    constructor(){
    }
}

export class SetInstitutionsAction implements Action {
    readonly type=SET_INSTITUTIONS;
    constructor( public institutions: any[]){
    }
}

export class UnsetInstitutionsAction implements Action {
    readonly type=UNSET_INSTITUTIONS;
    constructor(){
    }
}

export class SetInstitutionChosenAction implements Action {
    readonly type=SET_INSTITUTION_CHOSEN;
    constructor( public institution: any){
    }
}

export class UnsetInstitutionChosenAction implements Action {
    readonly type=UNSET_INSTITUTION_CHOSEN;
    constructor(){
    }
}

export class UnsetAllAction implements Action {
    readonly type = UNSET_ALL;
}

export type authActions =   SetUserAction               |
                            UnsetUserAction             |
                            SetTokenAction              |
                            UnsetTokenAction            |
                            SetRefreshTokenAction       |
                            UnsetRefreshTokenAction     |
                            SetInstitutionsAction       |
                            UnsetInstitutionsAction     |
                            SetInstitutionChosenAction  |
                            UnsetInstitutionChosenAction|
                            UnsetAllAction;
