
import * as fromNewSchedule from '../actions/availability-flag.actions';

export interface AvailibilityFlagState{
    availableToChange: boolean
}

const initialState: AvailibilityFlagState={
    availableToChange: false
};

export function availabilityFlagReducer (state=initialState,action:fromNewSchedule.availabilityFlagActions): AvailibilityFlagState{
    switch (action.type) {        
        case fromNewSchedule.SET_AVAILABILITY_TO_CHANGE:
            return{
                ...state,
                availableToChange: action.availableToChange,
            };

        case fromNewSchedule.UNSET_AVAILABILITY_TO_CHANGE:
            return {
                ...state,
                availableToChange: true,
            };
        
        default:
            return state;
    }
}

