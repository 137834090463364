import { Action } from "@ngrx/store";


export const SET_SHOW_PANEL = '[Others] Set show panel';
export const RESET_APP = '[Others] Reset app';
export const SET_NUVALID_ACCESS = '[Others] Set Nuvalid Access';
export const SET_SCROLL_ENTERPRISE = '[Others] Set scroll';



export class SetShowPanelAction implements Action {
    readonly type=SET_SHOW_PANEL;
    constructor( public payload:any){
    }
}

export class ResetAppAction implements Action {
    readonly type=RESET_APP;
}

export class SetNuvalidAccessAction implements Action {
    readonly type=SET_NUVALID_ACCESS;
    constructor( public payload:any){
    }
}

export class SetScrollEnterpriseAction implements Action {
    readonly type=SET_SCROLL_ENTERPRISE;
    constructor( public payload:any){
    }
}


export type othersActions=  SetShowPanelAction |
                            ResetAppAction      |
                            SetNuvalidAccessAction  |
                            SetScrollEnterpriseAction
