
import * as fromCreatedTurns from '../actions/created-turns.actions';


export interface CreatedTurnsState{

    turnsList:any[] | null; 
    joinableTurns:any[] | null; 
    selectedTurn:any | null; 
    filters:any | null;
    extraTurn:any | null;
    reassignTurn:any | null;
}

const initialState: CreatedTurnsState={
    turnsList:null,
    selectedTurn:null,
    joinableTurns: null,
    filters:{
        status:null,
        speciality:null,
        provider:null,
        dateFrom:null,
        dateTo:null,
        schedule:null
    },
    extraTurn:{
        selectedSchedule:null,
        selectedClient:null
    },
    reassignTurn:null
};

export function createdTurnsReducer (state=initialState,action:fromCreatedTurns.createdTurnsActions): CreatedTurnsState{
    switch (action.type) {
        case fromCreatedTurns.SET_CREATED_TURNS_LIST:
            return{
                ...state,
                turnsList: [... action.payload],
                
            };
        case fromCreatedTurns.UNSET_CREATED_TURNS_LIST:
            return {
                ...state,
                turnsList: null,
                
            };
        case fromCreatedTurns.SET_CREATED_SELECTED_TURN:
            return{
                ...state,
                selectedTurn: {...action.payload},
                
            };
        case fromCreatedTurns.UNSET_CREATED_SELECTED_TURN:
            return {
                ...state,
                selectedTurn: null,
                
            }; 
        
        case fromCreatedTurns.SET_JOINABLE_TURNS:
            return{
                ...state,
                joinableTurns: action.payload,
            };
        case fromCreatedTurns.UNSET_JOINABLE_TURNS:
            return {
                ...state,
                joinableTurns: null,
            }; 

        case fromCreatedTurns.SET_CREATED_TURN_FILTERS:
            return{
                ...state,
                filters: {...action.payload},
                
            };
        case fromCreatedTurns.UNSET_CREATED_TURN_FILTERS:
            return {
                ...state,
                filters: null,
                
            };        

        case fromCreatedTurns.SET_EXTRATURN:
            return{
                ...state,
                extraTurn: {...action.payload},
                
            };
        case fromCreatedTurns.UNSET_EXTRATURN:
            return {
                ...state,
                extraTurn: null,
                
            };        
        case fromCreatedTurns.SET_REASSIGNTURN:
            return{
                ...state,
                reassignTurn: {...action.payload},
                
            };
        case fromCreatedTurns.UNSET_REASSIGNTURN:
            return {
                ...state,
                reassignTurn: null,
                
            };        
        
        default:
            return state;
    }
}

