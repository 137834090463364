import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { of, switchMap } from 'rxjs';

export const NoClientGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).isClient().pipe(
        switchMap((clientAuthenticated) =>
        {
            // If the user is not a client...
            if ( clientAuthenticated == true )
            {
                // Redirect to the user-sign-in page 
                const urlTree = router.parseUrl(`user-sign-in`);
                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};
