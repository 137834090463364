
import * as fromMapData from '../actions/map-data.actions';


export interface MapDataState{
    coordinatesChosen: any | null,
    addressChosen: any | null
}

const initialState: MapDataState={
    coordinatesChosen: null,
    addressChosen: null
};

export function mapDataReducer (state=initialState,action:fromMapData.mapDataActions): MapDataState{
    
    switch (action.type) {
        case fromMapData.SET_MAP_COORDINATES:
            return{
                ...state,
                coordinatesChosen: action.payload,
                
            };
        case fromMapData.UNSET_MAP_COORDINATES:
            return {
                ...state,
                coordinatesChosen: null,
            }; 
        case fromMapData.SET_ADDRESS_CHOSEN:
            return{
                ...state,
                addressChosen: action.payload,
                
            };
        case fromMapData.UNSET_ADDRESS_CHOSEN:
            return {
                ...state,
                addressChosen: null,
            };      
        
        default:
            return state;
    }
}

