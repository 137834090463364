import * as fromClient from '../actions/client.actions';

export interface ClientState{
    client :any | null
}

const initialState: ClientState={
    client: null
}

export function clientsReducer (state=initialState,action:fromClient.clientsActions): ClientState{
    switch (action.type) {
        case fromClient.SET_CLIENT:
            return{
                ...state,
                client: action.payload,
            };
        case fromClient.UNSET_CLIENT:
            return {
                ...state,
                client: null,
            };
        default:
            return state;
    }
}

