import { Action } from "@ngrx/store";


export const SET_EXISTING_SCHEDULES = '[UpdateSchedule] Set Existing Schedules';
export const UNSET_EXISTING_SCHEDULES = '[UpdateSchedule] Unset Existing Schedules';
export const SET_SELECTED_SCHEDULE = '[UpdateSchedule] Set Selected Schedule';
export const UNSET_SELECTED_SCHEDULE = '[UpdateSchedule] Unset Selected Schedule';

export class SetExistingSchedulesAction implements Action {
    readonly type=SET_EXISTING_SCHEDULES;
    constructor( public createdSchedules:any){
    }
}

export class UnsetExistingSchedulesAction implements Action {
    readonly type = UNSET_EXISTING_SCHEDULES;
}

export class SetSelectedScheduleAction implements Action {
    readonly type=SET_SELECTED_SCHEDULE;
    constructor( public selectedSchedule:any){
    }
}

export class UnsetSelectedScheduleAction implements Action {
    readonly type = UNSET_SELECTED_SCHEDULE;
}

export type updateScheduleActions=  SetExistingSchedulesAction      |
                                    UnsetExistingSchedulesAction    |
                                    SetSelectedScheduleAction       |
                                    UnsetSelectedScheduleAction     ;
