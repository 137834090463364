
import * as fromFindClientTurn from '../actions/find-client-turn.actions';


export interface FindClientTurnState{
    scheduleList:any[] | null;
    selectedSchedule:any | null;
    turnsList:any[] | null;
    filters:any | null;
    selectedTurn:any | null;
    selectedDay:any | null;
    institutionsFound:any | null;
    institutionChosen: any | null
}

const initialState: FindClientTurnState={
    scheduleList:null,
    selectedSchedule:null,
    turnsList:null,
    selectedTurn:null,
    selectedDay:null,
    filters:{
        status:null,
        speciality:null,
        provider:null,
        dateFrom:null,
        dateTo:null,
        coordinates:null
    },
    institutionsFound:null,
    institutionChosen: null
};

export function findClientTurnReducer (state=initialState,action:fromFindClientTurn.findClientTurnActions): FindClientTurnState{
    switch (action.type) {
        case fromFindClientTurn.SET_FIND_CLIENT_SCHEDULE_LIST:
            return{
                ...state,
                scheduleList: [... action.payload],

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_SCHEDULE_LIST:
            return {
                ...state,
                scheduleList: null,

            };

        case fromFindClientTurn.SET_FIND_CLIENT_SELECTED_SCHEDULE:
            return{
                ...state,
                selectedSchedule: {...action.payload},

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_SELECTED_SCHEDULE:
            return {
                ...state,
                selectedSchedule: null,

            };

        case fromFindClientTurn.SET_FIND_CLIENT_TURN_LIST:
            return{
                ...state,
                turnsList: [... action.payload],

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_TURN_LIST:
            return {
                ...state,
                turnsList: null,

            };
        case fromFindClientTurn.SET_FIND_CLIENT_SELECTED_TURN:
            return{
                ...state,
                selectedTurn: {...action.payload},

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_SELECTED_TURN:
            return {
                ...state,
                selectedTurn: null,

            };

        case fromFindClientTurn.SET_FIND_CLIENT_SELECTED_DAY:
            return{
                ...state,
                selectedDay: {...action.payload},

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_SELECTED_DAY:
            return {
                ...state,
                selectedDay: null,

            };

        case fromFindClientTurn.SET_FIND_CLIENT_TURN_FILTERS:
            return{
                ...state,
                filters: {...action.payload},

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_TURN_FILTERS:
            return {
                ...state,
                filters: {
                    status:null,
                    speciality:null,
                    provider:null,
                    dateFrom:null,
                    dateTo:null,
                    coordinates:null
                },

            };

        case fromFindClientTurn.SET_FIND_CLIENT_TURN_INSTITUTIONS:
            return{
                ...state,
                institutionsFound: [... action.payload],

            };
        case fromFindClientTurn.UNSET_FIND_CLIENT_TURN_INSTITUTIONS:
            return {
                ...state,
                institutionsFound: null,

            };

        case fromFindClientTurn.SET_FIND_CLIENT_TURN_INSTITUTION_CHOSEN:
            return{
                ...state,
                institutionChosen: action.payload,

            };

        case fromFindClientTurn.UNSET_FIND_CLIENT_TURN_INSTITUTION_CHOSEN:
            return {
                ...state,
                institutionChosen: null,

            };

        default:
            return state;
    }
}

