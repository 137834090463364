import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import { AuthService } from 'app/services/auth.service';

import { authInterceptor } from 'app/core/auth/auth.interceptor';
import { tokenInterceptor } from 'app/interceptors/token.interceptor';
import { AuthServices } from './auth.service';

export const provideAuth = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        provideHttpClient(withInterceptors([authInterceptor, tokenInterceptor])),
        {
            provide : ENVIRONMENT_INITIALIZER,
            useValue: () => inject(AuthServices),
            multi   : true,
        },
    ];
};
