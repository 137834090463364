import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { of, switchMap, Observable, take, catchError, tap } from 'rxjs';

//Store
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';

import * as storeActions from '../store/actions';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { PersistenceService } from './persistence.service';
import { EncryptionService } from './encryption.service';
import { FuseConfigService } from '@fuse/services/config';

interface refreshToken {
    refreshToken: string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  headers = new HttpHeaders();
  // private _authenticated: boolean = false;

  constructor(private http: HttpClient,
              private store:Store<AppState>,
              private _persistenceService: PersistenceService,
              private _router: Router,
              private encryptionService: EncryptionService,
              private _fuseConfigService: FuseConfigService,
              ) { }

  get institutionUuid(): string {
    let institutionUuid=''
    this.store.select('auth').subscribe(auth=>{
      institutionUuid=auth.currentUser?.institution?.uuid;
    });
    return institutionUuid;
  }

  get currentUser(): any {
    let currentUser=''
    this.store.select('auth').subscribe(auth=>{
      currentUser=auth.currentUser;
    });
    return currentUser;
  }

  get clientAuthenticated(): boolean {
    let clientAuthenticated=false;
    this.store.select('auth').subscribe(authData=>{
      if(authData.currentUser?.role == 'CLIENT'){
        clientAuthenticated = true;
      } else {
        clientAuthenticated = false;
      }
    });

    return clientAuthenticated;
  }

  get providerAuthenticated(): boolean {
    let providerAuthenticated=false;
    this.store.select('auth').subscribe(authData=>{
      if(authData.currentUser?.role == 'PROVIDER'){
        providerAuthenticated = true;
      } else {
        providerAuthenticated = false;
      }
    });

    return providerAuthenticated;
  }

  get authenticated(): boolean {
    let authenticated=false;
    this.store.select('auth').subscribe(auth=>{
      if(auth.currentToken !== null){
        authenticated = true;
      } else {
        authenticated = false;
      }
    });

    return authenticated;
  }

  get accessToken(): string {
    let accessToken=''
    this.store.select('auth').subscribe(auth=>{
      accessToken=auth.currentToken;
    });
    return accessToken;
  }

  get refreshToken(): string {
    let refreshToken=''
    this.store.select('auth').subscribe(auth=>{
      refreshToken=auth.refreshToken;
    });
    return refreshToken;
  }

  accessLogin(dataLogin: { email: string; password: string, codeInstitution?: string }): Observable<any>{
    return this.http
      //.post<CurrentUserModel>(environment.url + '/api/auth/login', infoLogin)
      .post(`${environment.url}/v1/auth/signin`, dataLogin,{headers:this.headers})
      .pipe(switchMap((response: any) =>
      {
        if(response.data.user.role !== 'CLIENT'){
          this._fuseConfigService.config = {layout:'classy'}
        }

        this._persistenceService.setCurrentUser(response.data.user);
        this._persistenceService.setToken(response.data.token);
        this._persistenceService.setRefreshToken(response.data.refreshToken);
        this.store.dispatch(new storeActions.SetUserAction(response.data.user))
        this.store.dispatch(new storeActions.SetTokenAction(response.data.token))
        this.store.dispatch(new storeActions.SetRefreshTokenAction(response.data.refreshToken))

        // Store the access token in the local storage
        // this.accessToken = response.accessToken;

        // // Set the authenticated flag to true
        // this._authenticated = true;

        // // Store the user on the user service
        // this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      }),);
  }

  userSignIn(dataLogin: { email: string; password: string }): Observable<any>{
    return this.http
      //.post<CurrentUserModel>(environment.url + '/api/auth/login', infoLogin)
      .post(`${environment.url}/v1/auth/signin`, dataLogin,{headers:this.headers})
      .pipe(switchMap((response: any) =>
      {

        if(response.data.user.role == 'CLIENT'){
            this._fuseConfigService.config = {layout:'enterprise'}
        }
        this._persistenceService.setCurrentUser(response.data.user);
        this._persistenceService.setToken(response.data.token);
        this._persistenceService.setRefreshToken(response.data.refreshToken);
        this.store.dispatch(new storeActions.SetUserAction(response.data.user))
        this.store.dispatch(new storeActions.SetTokenAction(response.data.token))
        this.store.dispatch(new storeActions.SetRefreshTokenAction(response.data.refreshToken))

        // Return a new observable with the response
        return of(response);
      }),);
  }

  accessLoginNuvalid(dataEncryption): Observable<any>{
    let dataNuvalid:any = JSON.parse(this.encryptionService.decrypt(dataEncryption));
    let { token, ...rest } = dataNuvalid; //Separo el token
    this.store.dispatch(new storeActions.SetTokenAction(token));
    return this.http
      //.post<CurrentUserModel>(environment.url + '/api/auth/login', infoLogin)
      .post(`${environment.url}/v1/auth/signin-nuvalid`, rest,this.headers_http)
      .pipe(switchMap((response: any) =>
      {
        this._persistenceService.setCurrentUser(response.data.user);
        this._persistenceService.setToken(response.data.token);
        this._persistenceService.setRefreshToken(response.data.refreshToken);
        this.store.dispatch(new storeActions.SetUserAction(response.data.user))
        this.store.dispatch(new storeActions.SetTokenAction(response.data.token))
        this.store.dispatch(new storeActions.SetRefreshTokenAction(response.data.refreshToken))

          // console.log(response);

          // Store the access token in the local storage
          // this.accessToken = response.accessToken;

          // // Set the authenticated flag to true
          // this._authenticated = true;

          // // Store the user on the user service
          // this._userService.user = response.user;

          // Return a new observable with the response
          return of(response);
      }),);
  }

  /**
   * Check the authentication status
   * @returns true or false
   */
  check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this.authenticated == true)
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the refresh token availability
        if ( !this.refreshToken )
            {
                return of(false);
            }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();

        // TODO Uncomment next line and comment all lines above to use Turnea without checking log in information
        // return of(true);
    }

    /**
     * Check the user role
     * @returns true or false
     */
    isClient(): Observable<boolean>{
      // Check if the user is a client
      if (this.clientAuthenticated == true)
      {
          return of(true);
      }

      // If the user is not a client
      return of(false);
    }

    /**
     * Check the user role
     * @returns true or false
     */
    isProvider(): Observable<boolean>{
      // Check if the user is a provider
      if (this.providerAuthenticated == true)
      {
          return of(true);
      }

      // If the user is not a provider
      return of(false);
  }

  /**
   * Sign out
   */
  signOut(): Promise<any>
  {
    // Remove the access token from the local storage
    return new Promise<any | void>( (resolve,reject) => {
      this.http.post(`${environment.url}/v1/auth/signout`, null, this.headers_http).subscribe(
        {
          next(res:any) {
            resolve(res)
          },
          error(msg) {
            reject(msg)
          }
      });
    })
  }

  /**
    * Sign out
    */
  singError(): Observable<any>
  {
    // Remove the access token from the local storage
    this.store.dispatch(new storeActions.UnsetTokenAction())
    this.store.dispatch(new storeActions.UnsetRefreshTokenAction())

    this._router.navigateByUrl('/404-not-found-nuvalid')
    // Return the observable
    return of(true);
  }

  /**
     * Sign in using the access token
     */
  signInUsingToken(): Observable<any>
  {
      // Sign in using the token
      return this.http.post('api/auth/sign-in-with-token', {
          accessToken: this.accessToken,
      }).pipe(
          catchError(() =>

              // Return false
              of(false),
          ),
          switchMap((response: any) =>
          {
              // Replace the access token with the new one if it's available on
              // the response object.
              if ( response.data?.token )
              {
                this.store.dispatch(new storeActions.SetTokenAction(response.data.token))
                this.store.dispatch(new storeActions.SetRefreshTokenAction(response.data.refreshToken))
              }

              // Store the user on the user service
              this.store.dispatch(new storeActions.SetUserAction(response.data.user))

              // Return true
              return of(true);
          }),
      );
  }

  getInstitutionsByUserEmail(email: any): Promise<any>{
     return new Promise<any | void>( (resolve,reject) => {
      this.http.get(`${environment.url}/v1/institutions/users?email=${email}`, this.headers_http).subscribe( {
          next(res:any) {
            resolve(res.data)
          },
          error(msg) {
            reject(msg)
          }
       });
     })
  }

  /**
     * Sign up
     */
  signUp(body: any): Observable<any>
  {
    return this.http.post(`${environment.url}/v1/auth/signup`, body, this.headers_http).pipe(
        catchError((error: any) =>
            // Return false
            of(error),
        ),
        switchMap((response: any) =>
        {
          // Return true
          return of(response);
        }),
    );
  }

  /**
   * Sign up employee by link
   */
  signUpByLink(body: any): Observable<any>
  {
    return this.http.post(`${environment.url}/v1/auth/signup-by-link`, body, this.headers_http).pipe(
      catchError((error: any) =>
          // Return false
          of(error),
      ),
      switchMap((response: any) =>
      {
        // Return true
        return of(response);
      }),
    );
  }

  /**
   * Delete a user account
   * @param userUuid user's uuid
   * @returns the delete response
   */
  deleteAccount(userUuid: string){
    return this.http.delete(`${environment.url}/v1/auth/${userUuid}`, this.headers_http);
  }

  deleteAccountPromise(userUuid: any): Promise<any>{

    return new Promise<any | void>( (resolve,reject) => {

      this.http.delete(`${environment.url}/v1/auth/${userUuid}`, this.headers_http).subscribe(
        {
          next(res:any) {
            resolve(res)
          },
          error(msg) {
            reject(msg)
          }
      });

    });

  }

  // forgotPassword(body: any): Promise<any>{
  //   return new Promise<any | void>( (resolve,reject) => {
  //     this.http.post(`${environment.url}/v1/auth/forgot-password`, body, this.headers_http).subscribe(
  //       {
  //         next(res:any) {
  //           resolve(res)
  //         },
  //         error(msg) {
  //           reject(msg)
  //         }
  //     });
  //   })
  // }

  forgotPassword(body: any){
    return this.http.post(`${environment.url}/v1/auth/forgot-password`, body, this.headers_http);
  }

  refreshTokenMethod(){
    const body: refreshToken = {
        refreshToken: this.refreshToken
    }
    return this.http.post(`${environment.url}/v1/auth/refresh-token`, body, this.headers_http).pipe(tap((resp:any) => resp));
  }

  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.accessToken}`
    }
    return {headers:header};
  }
}
