import { Action } from "@ngrx/store";


export const SET_USERS_LIST = '[Users] Set users list';
export const UNSET_USERS_LIST = '[Users] Unset users list';

export const SET_SELECTED_USER = '[Users] Set selected user';
export const UNSET_SELECTED_USER = '[Users] Unset selected user';

export const SET_ROLES = '[Users] Set roles';
export const UNSET_ROLES = '[Users] Unset roles';

export const SET_USERS_LENGTH = '[Users] Set users length';
export const UNSET_USERS_LENGTH = '[Users] Unset users length';


export class SetUsersListAction implements Action {
    readonly type=SET_USERS_LIST;
    constructor( public payload:any){
    }
}

export class UnSetUsersListAction implements Action {
    readonly type=UNSET_USERS_LIST;
    
}

export class SetSelectedUserAction implements Action {
    readonly type=SET_SELECTED_USER;
    constructor( public payload:any){
    }
}

export class UnSetSelectedUserAction implements Action {
    readonly type=UNSET_SELECTED_USER;
    
}

export class SetRolesAction implements Action {
    readonly type=SET_ROLES;
    constructor( public payload:any){
    }
}

export class UnSetRolesAction implements Action {
    readonly type=UNSET_ROLES;
    
}

export class SetUsersLengthAction implements Action {
    readonly type=SET_USERS_LENGTH;
    constructor( public payload:any){
    }
}

export class UnsetUsersLengthAction implements Action {
    readonly type=UNSET_USERS_LENGTH;
    
}

export type usersActions=   SetUsersListAction      |
                            UnSetUsersListAction    |
                            SetSelectedUserAction   |
                            UnSetSelectedUserAction |
                            SetRolesAction          |
                            UnSetRolesAction        |
                            SetUsersLengthAction    |
                            UnsetUsersLengthAction  ;         
