
import * as fromFunders from '../actions/funders.actions';

export interface FundersState{
    fundersChosen: any,
}

const initialState: FundersState={
    fundersChosen: null,
};

export function fundersReducer (state = initialState, action: fromFunders.fundersActions): FundersState{
    switch (action.type) {
        case fromFunders.SET_FUNDERS_CHOSEN:
            return{
                fundersChosen: action.funders,
            };
        case fromFunders.UNSET_FUNDERS_CHOSEN:
            return {
                fundersChosen: null,
            };
        
        default:
            return state;
    }
}

