import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Store } from '@ngrx/store';
import { compactNavigation, defaultAdminNavigation, defaultNavigation, defaultProviderNavigation, defaultUserNavigation, futuristicNavigation, horizontalNavigation, horizontalProviderNavigation, horizontalUserNavigation } from 'app/mock-api/common/navigation/data';
import { AppState } from 'app/store/app.reducer';
import { cloneDeep } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class NavigationMockApi
{
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _defaultAdminNavigation: FuseNavigationItem[] = defaultAdminNavigation;
    private readonly _defaultProviderNavigation: FuseNavigationItem[] = defaultProviderNavigation;
    private readonly _horizontalProviderNavigation: FuseNavigationItem[] = horizontalProviderNavigation;
    private readonly _defaultUserNavigation: FuseNavigationItem[] = defaultUserNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    private readonly _horizontalUserNavigation: FuseNavigationItem[] = horizontalUserNavigation;
    private role: any

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService,
                private _store: Store<AppState>)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------

        this._store.select('auth').subscribe( (data: any) => {
            this.role = data?.currentUser?.role;
            if(this.role !== undefined){
                if(this.role === 'CLIENT'){
                    console.log('client role');
                    
                    this._fuseMockApiService
                    .onGet('api/common/navigation')
                    .reply(() =>
                    {
                        // Fill compact navigation children using the default navigation
                        this._compactNavigation.forEach((compactNavItem) =>
                        {
                            this._defaultUserNavigation.forEach((defaultNavItem) =>
                            {
                                if ( defaultNavItem.id === compactNavItem.id )
                                {
                                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                                }
                            });
                        });
        
                        // Fill futuristic navigation children using the default navigation
                        this._futuristicNavigation.forEach((futuristicNavItem) =>
                        {
                            this._defaultUserNavigation.forEach((defaultNavItem) =>
                            {
                                if ( defaultNavItem.id === futuristicNavItem.id )
                                {
                                    futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                                }
                            });
                        });
        
                        // Fill horizontal navigation children using the default navigation
                        this._horizontalUserNavigation.forEach((horizontalNavItem) =>
                        {
                            this._defaultUserNavigation.forEach((defaultNavItem) =>
                            {
                                if ( defaultNavItem.id === horizontalNavItem.id )
                                {
                                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                                }
                            });
                        });
        
                        // Return the response
                        return [
                            200,
                            {
                                compact   : cloneDeep(this._compactNavigation),
                                default   : cloneDeep(this._defaultUserNavigation),
                                futuristic: cloneDeep(this._futuristicNavigation),
                                horizontal: cloneDeep(this._horizontalUserNavigation),
                            },
                        ];
                    });                    
                } else if(this.role === 'ADMIN_INSTITUTION'){

                    this._fuseMockApiService
                        .onGet('api/common/navigation')
                        .reply(() =>
                        {
                            // Fill compact navigation children using the default navigation
                            this._compactNavigation.forEach((compactNavItem) =>
                            {
                                this._defaultAdminNavigation.forEach((defaultNavItem) =>
                                {
                                    if(defaultNavItem.id !== 'institutions_admin'){
                                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                                    }
                                });
                            });
            
                            // Fill futuristic navigation children using the default navigation
                            this._futuristicNavigation.forEach((futuristicNavItem) =>
                            {
                                this._defaultAdminNavigation.forEach((defaultNavItem) =>
                                {
                                    if(defaultNavItem.id !== 'institutions_admin'){
                                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                                    }
                                });
                            });
            
                            // Fill horizontal navigation children using the default navigation
                            this._horizontalNavigation.forEach((horizontalNavItem) =>
                            {
                                this._defaultAdminNavigation.forEach((defaultNavItem) =>
                                {
                                    if(defaultNavItem.id !== 'institutions_admin'){
                                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                                    }
                                });
                            });                            
            
                            // Return the response
                            return [
                                200,
                                {
                                    compact   : cloneDeep(this._compactNavigation),
                                    default   : cloneDeep(this._defaultAdminNavigation),
                                    futuristic: cloneDeep(this._futuristicNavigation),
                                    horizontal: cloneDeep(this._horizontalNavigation),
                                },
                            ];
                        });
                } else if(this.role === 'PROVIDER'){

                    this._fuseMockApiService
                        .onGet('api/common/navigation')
                        .reply(() =>
                        {
                            // Fill compact navigation children using the default navigation
                            this._compactNavigation.forEach((compactNavItem) =>
                            {
                                this._defaultProviderNavigation.forEach((defaultNavItem) =>
                                {
                                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                                });
                            });
            
                            // Fill futuristic navigation children using the default navigation
                            this._futuristicNavigation.forEach((futuristicNavItem) =>
                            {
                                this._defaultProviderNavigation.forEach((defaultNavItem) =>
                                {
                                    futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                                });
                            });
            
                            // Fill horizontal navigation children using the default navigation
                            this._horizontalNavigation.forEach((horizontalNavItem) =>
                            {
                                this._horizontalProviderNavigation.forEach((defaultNavItem) =>
                                {
                                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                                });
                            });                            
            
                            // Return the response
                            return [
                                200,
                                {
                                    compact   : cloneDeep(this._compactNavigation),
                                    default   : cloneDeep(this._defaultProviderNavigation),
                                    futuristic: cloneDeep(this._futuristicNavigation),
                                    horizontal: cloneDeep(this._horizontalProviderNavigation),
                                },
                            ];
                        });                    
                } else {
                    this._fuseMockApiService
                        .onGet('api/common/navigation')
                        .reply(() =>
                        {
                            // Fill compact navigation children using the default navigation
                            this._compactNavigation.forEach((compactNavItem) =>
                            {
                                this._defaultNavigation.forEach((defaultNavItem) =>
                                {                                    
                                    if ( defaultNavItem.id === compactNavItem.id )
                                    {
                                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                                    }
                                });
                            });
            
                            // Fill futuristic navigation children using the default navigation
                            this._futuristicNavigation.forEach((futuristicNavItem) =>
                            {
                                this._defaultNavigation.forEach((defaultNavItem) =>
                                {
                                    if ( defaultNavItem.id === futuristicNavItem.id )
                                    {
                                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                                    }
                                });
                            });
            
                            // Fill horizontal navigation children using the default navigation
                            this._horizontalNavigation.forEach((horizontalNavItem) =>
                            {
                                this._defaultNavigation.forEach((defaultNavItem) =>
                                {
                                    if ( defaultNavItem.id === horizontalNavItem.id )
                                    {
                                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                                    }
                                });
                            });
                            
                            // Return the response
                            return [
                                200,
                                {
                                    compact   : cloneDeep(this._compactNavigation),
                                    default   : cloneDeep(this._defaultNavigation),
                                    futuristic: cloneDeep(this._futuristicNavigation),
                                    horizontal: cloneDeep(this._horizontalNavigation),
                                },
                            ];
                        });
                }
            }
            
        })

        
    }
}
