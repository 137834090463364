
import * as fromUpdateSchedule from '../actions/update-schedule.actions';

export interface UpdateScheduleState{
    createdSchedules: any,
    selectedSchedule: any,
    length: null
}

const initialState: UpdateScheduleState={
    createdSchedules: null,
    selectedSchedule: null,
    length: null
};

export function updateScheduleReducer (state=initialState,action:fromUpdateSchedule.updateScheduleActions): UpdateScheduleState{
    switch (action.type) {
        case fromUpdateSchedule.SET_EXISTING_SCHEDULES:
            return{
                ...state,
                createdSchedules: action.createdSchedules.schedules,
                length: action.createdSchedules.cant
            };
        case fromUpdateSchedule.UNSET_EXISTING_SCHEDULES:
            return {
                ...state,
                createdSchedules: null,
                length: null
            };
        case fromUpdateSchedule.SET_SELECTED_SCHEDULE:
            return{
                ...state,
                selectedSchedule: action.selectedSchedule,
            };
        case fromUpdateSchedule.UNSET_SELECTED_SCHEDULE:
            return {
                ...state,
                selectedSchedule: null,
            };
        
        default:
            return state;
    }
}

