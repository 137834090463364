import { Action } from "@ngrx/store";

export const SET_AVAILABILITY_TO_CLEAR = '[ClearSearcherFlag] Set availability to clear searcher';
export const UNSET_AVAILABILITY_TO_CLEAR = '[ClearSearcherFlag] Unset availability to clear searcher';

export class SetAvailabilityToClearAction implements Action {
    readonly type=SET_AVAILABILITY_TO_CLEAR;
    constructor( public availableToClear:any){
    }
}

export class UnsetAvailabilityToClearAction implements Action {
    readonly type = UNSET_AVAILABILITY_TO_CLEAR;
}


export type availabilityToClearActions =    SetAvailabilityToClearAction   |
                                            UnsetAvailabilityToClearAction;
