import { Action } from "@ngrx/store";

export const SET_STEP_ORDER = '[StepOrder] Set Step Order';
export const UNSET_STEP_ORDER = '[StepOrder] Unset Step Order';

export class SetStepOrderAction implements Action {
    readonly type=SET_STEP_ORDER;
    constructor( public stepInfo:any){
    }
}

export class UnsetStepOrderAction implements Action {
    readonly type = UNSET_STEP_ORDER;
}

export type stepOrderActions=   SetStepOrderAction  |
                                UnsetStepOrderAction;
