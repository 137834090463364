
import * as fromAuth from '../actions/auth.actions';

export interface AuthState{
    currentUser:        any;
    currentToken:       string;
    refreshToken:       string;
    institutions:       any[] | null;
    institutionChosen:  any
}

const initialState: AuthState={
    currentUser:    null,
    currentToken:   null,
    refreshToken:   null,
    institutions:   null,
    institutionChosen:   null
};

export function authReducer (state=initialState,action:fromAuth.authActions): AuthState{
    switch (action.type) {
        case fromAuth.SET_USER:
            return{
                ...state,
                currentUser: {... action.user}
            };
        case fromAuth.UNSET_USER:
            return {
                ...state,
                currentUser: null,
                currentToken: null
            };

        case fromAuth.SET_TOKEN:
            return{
                ...state,
                currentToken: action.token
            };

        case fromAuth.SET_REFRESH_TOKEN:
            return{
                ...state,
                refreshToken: action.refreshToken
            };

        case fromAuth.SET_INSTITUTIONS:
            return{
                ...state,
                institutions: action.institutions
            };
        case fromAuth.UNSET_INSTITUTIONS:
            return {
                ...state,
                institutions: null
            };

        case fromAuth.SET_INSTITUTION_CHOSEN:
            return{
                ...state,
                institutionChosen: action.institution
            };
        case fromAuth.UNSET_INSTITUTION_CHOSEN:
            return {
                ...state,
                institutionChosen: null
            };

        case fromAuth.UNSET_INSTITUTION_CHOSEN:
            return {
                ...state,
                refreshToken: null
            };

        case fromAuth.UNSET_ALL:
            return {
                currentUser:    null,
                currentToken:   null,
                refreshToken:   null,
                institutions:   null,
                institutionChosen:   null
            };

        default:
            return state;
    }
}

