import { Action } from "@ngrx/store";



export const SET_FIND_CLIENT_SCHEDULE_LIST = '[Find Client Turn] Set SCHEDULES list';
export const UNSET_FIND_CLIENT_SCHEDULE_LIST = '[Find Client Turn] Unset SCHEDULES list';

export const SET_FIND_CLIENT_SELECTED_SCHEDULE = '[Find Client Turn] Set Find Client SCHEDULE';
export const UNSET_FIND_CLIENT_SELECTED_SCHEDULE = '[Find Client Turn] Unset Find Client SCHEDULE';

export const SET_FIND_CLIENT_TURN_LIST = '[Find Client Turn] Set turns list';
export const UNSET_FIND_CLIENT_TURN_LIST = '[Find Client Turn] Unset turns list';

export const SET_FIND_CLIENT_SELECTED_TURN = '[Find Client Turn] Set Find Client Turn';
export const UNSET_FIND_CLIENT_SELECTED_TURN = '[Find Client Turn] Unset Find Client Turn';

export const SET_FIND_CLIENT_SELECTED_DAY = '[Find Client Turn] Set Find Client DAY';
export const UNSET_FIND_CLIENT_SELECTED_DAY = '[Find Client Turn] Unset Find Client DAY';

export const SET_FIND_CLIENT_TURN_FILTERS = '[Find Client Turn] Set Find Client turn filters';
export const UNSET_FIND_CLIENT_TURN_FILTERS = '[Find Client Turn] Unset Find Client Turn turn filters';

export const SET_FIND_CLIENT_TURN_INSTITUTIONS = '[Find Client Turn] Set institutions';
export const UNSET_FIND_CLIENT_TURN_INSTITUTIONS = '[Find Client Turn] Unset institutions';

export const SET_FIND_CLIENT_TURN_INSTITUTION_CHOSEN = '[Find Client Turn] Set institution chosen';
export const UNSET_FIND_CLIENT_TURN_INSTITUTION_CHOSEN = '[Find Client Turn] Unset institution chosen';


export class SetFindClientScheduleListAction implements Action {
    readonly type=SET_FIND_CLIENT_SCHEDULE_LIST;
    constructor( public payload:any[]){
    }
}

export class UnSetFindClientScheduleListAction implements Action {
    readonly type=UNSET_FIND_CLIENT_SCHEDULE_LIST;
    
}

export class SetFindClientSelectedScheduleAction implements Action {
    readonly type=SET_FIND_CLIENT_SELECTED_SCHEDULE;
    constructor( public payload:any){
    }
}

export class UnSetFindClientSelectedScheduleAction implements Action {
    readonly type=UNSET_FIND_CLIENT_SELECTED_SCHEDULE;
    
}

export class SetFindClientTurnListAction implements Action {
    readonly type=SET_FIND_CLIENT_TURN_LIST;
    constructor( public payload:any[]){
    }
}

export class UnSetFindClientTurnListAction implements Action {
    readonly type=UNSET_FIND_CLIENT_TURN_LIST;
    
}

export class SetFindClientSelectedTurnAction implements Action {
    readonly type=SET_FIND_CLIENT_SELECTED_TURN;
    constructor( public payload:any){
    }
}

export class UnSetFindClientSelectedTurnAction implements Action {
    readonly type=UNSET_FIND_CLIENT_SELECTED_TURN;
    
}

export class SetFindClientSelectedDayAction implements Action {
    readonly type=SET_FIND_CLIENT_SELECTED_DAY;
    constructor( public payload:any){
    }
}

export class UnSetFindClientSelectedDayAction implements Action {
    readonly type=UNSET_FIND_CLIENT_SELECTED_DAY;
    
}

export class SetFindClientTurnFiltersAction implements Action {
    readonly type=SET_FIND_CLIENT_TURN_FILTERS;
    constructor( public payload:any){
    }
}

export class UnSetFindClientTurnFiltersAction implements Action {
    readonly type=UNSET_FIND_CLIENT_TURN_FILTERS;
    
}

export class SetFindClientTurnInstitutionsAction implements Action {
    readonly type=SET_FIND_CLIENT_TURN_INSTITUTIONS;
    constructor( public payload:any[]){
    }
}

export class UnSetFindClientTurnInstitutionsAction implements Action {
    readonly type=UNSET_FIND_CLIENT_TURN_INSTITUTIONS;
    
}

export class SetFindClientTurnInstitutionChosenAction implements Action {
    readonly type=SET_FIND_CLIENT_TURN_INSTITUTION_CHOSEN;
    constructor( public payload:any){
    }
}

export class UnsetFindClientTurnInstitutionChosenAction implements Action {
    readonly type=UNSET_FIND_CLIENT_TURN_INSTITUTION_CHOSEN;
    
}

export type findClientTurnActions=      SetFindClientScheduleListAction     |
                                        UnSetFindClientScheduleListAction   |
                                        SetFindClientSelectedScheduleAction |
                                        UnSetFindClientSelectedScheduleAction   |
                                        SetFindClientTurnListAction         |
                                        UnSetFindClientTurnListAction       |
                                        SetFindClientSelectedTurnAction     |
                                        UnSetFindClientSelectedTurnAction   |
                                        SetFindClientTurnFiltersAction      |
                                        UnSetFindClientTurnFiltersAction     |
                                        SetFindClientSelectedDayAction      |
                                        UnSetFindClientSelectedDayAction    |
                                        SetFindClientTurnInstitutionsAction |
                                        UnSetFindClientTurnInstitutionsAction |
                                        SetFindClientTurnInstitutionChosenAction |
                                        UnsetFindClientTurnInstitutionChosenAction
                                    
