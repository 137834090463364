import { Action } from "@ngrx/store";



export const SET_CREATED_TURNS_LIST = '[Created turns] Set turns list';
export const UNSET_CREATED_TURNS_LIST = '[Created turns] Unset turns list';

export const SET_CREATED_SELECTED_TURN = '[Created turns] Set assigned turn';
export const UNSET_CREATED_SELECTED_TURN = '[Created turns] Unset assigned turn';

export const SET_JOINABLE_TURNS = '[Created turns] Set possible joinable turns';
export const UNSET_JOINABLE_TURNS = '[Created turns] Unset possible joinable turns';

export const SET_CREATED_TURN_FILTERS = '[Created turns] Set Created turn filters';
export const UNSET_CREATED_TURN_FILTERS = '[Created turns] Unset Created turn filters';

export const SET_EXTRATURN = '[Created turns] Extraturn Set ';
export const UNSET_EXTRATURN = '[Created turns] Extraturn UnSet ';

export const SET_REASSIGNTURN = '[Created turns] Reassignturn Set ';
export const UNSET_REASSIGNTURN = '[Created turns] UnSet Reassignturn';



export class SetCreatedTurnsListAction implements Action {
    readonly type=SET_CREATED_TURNS_LIST;
    constructor( public payload:any[]){
    }
}

export class UnSetCreatedTurnsListAction implements Action {
    readonly type=UNSET_CREATED_TURNS_LIST;
    
}

export class SetCreatedSelectedTurnAction implements Action {
    readonly type=SET_CREATED_SELECTED_TURN;
    constructor( public payload:any){
    }
}

export class UnSetCreatedSelectedTurnAction implements Action {
    readonly type=UNSET_CREATED_SELECTED_TURN;
    
}

export class SetJoinableTurnsAction implements Action {
    readonly type = SET_JOINABLE_TURNS;
    constructor(public payload:any){
    }
}

export class UnsetJoinableTurnsAction implements Action {
    readonly type = UNSET_JOINABLE_TURNS;
    
}

export class SetCreatedTurnFiltersAction implements Action {
    readonly type=SET_CREATED_TURN_FILTERS;
    constructor( public payload:any){
    }
}

export class UnSetCreatedTurnFiltersAction implements Action {
    readonly type=UNSET_CREATED_TURN_FILTERS;
    
}

export class SetExtraturnAction implements Action {
    readonly type=SET_EXTRATURN;
    constructor( public payload:any){
    }
}

export class UnSetExtraturnAction implements Action {
    readonly type=UNSET_EXTRATURN;
    
}

export class SetReassignTurnAction implements Action {
    readonly type=SET_REASSIGNTURN;
    constructor( public payload:any){
    }
}

export class UnSetReassignTurnAction implements Action {
    readonly type=UNSET_REASSIGNTURN;
    
}

export type createdTurnsActions=    SetCreatedTurnsListAction       |
                                    UnSetCreatedTurnsListAction     |
                                    SetCreatedSelectedTurnAction    |
                                    UnSetCreatedSelectedTurnAction  |
                                    SetCreatedTurnFiltersAction     |
                                    UnSetCreatedTurnFiltersAction   |
                                    SetExtraturnAction              |
                                    UnSetExtraturnAction            |
                                    SetReassignTurnAction           |
                                    UnSetReassignTurnAction         |
                                    SetJoinableTurnsAction          |
                                    UnsetJoinableTurnsAction
                                   
