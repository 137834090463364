import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NoAuthGuard } from './guards/noAuth.guard';
import { ClientGuard } from './guards/client.guard';
import { NoClientGuard } from './guards/noClient.guard';
import { ProviderGuard } from './guards/provider.guard';
import { NoProviderGuard } from './guards/noProvider.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},
    {path: '404-not-found-nuvalid', pathMatch: 'full', loadChildren: () => import('app/pages/error/error-404/error-404.routes')},
    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'admin/turnos/turnos-asignados'},
    {path: 'signed-in-provider-redirect', pathMatch : 'full', redirectTo: 'admin/provider/turnos-propios'},
    // {path: 'signed-in-user-redirect', pathMatch : 'full', redirectTo: 'client/turn/find-turn'},
    {path: 'signed-in-user-redirect', pathMatch : 'full', redirectTo: 'admin/client/find-turn'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/auth/sign-in/sign-in.routes')},
            {path: 'user-sign-in', loadChildren: () => import('app/auth/clients/user-sign-in/user-sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/auth/sign-up/sign-up.routes')},
            {path: 'sign-up-invitation', loadChildren: () => import('app/auth/sign-up-invitation/sign-up-invitation.routes')},
            {path: 'user-sign-up', loadChildren: () => import('app/auth/clients/user-sign-up/user-sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/pages/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: 'admin',
        // canActivate: [AuthGuard, NoClientGuard],
        // canActivateChild: [AuthGuard, NoClientGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // CLIENT HEALTH
            {   path: 'client',
                canActivate: [ ClientGuard],
                canActivateChild: [ ClientGuard],
                children: [
                    {path: 'find-turn', loadChildren: () => import('app/components/client-app/find-turn/find-turn.routes')},
                    {path: 'list-schedules', loadChildren: () => import('app/components/client-app/list-schedules/list-schedules.routes')},
                    {path: 'schedule-turns', loadChildren: () => import('app/components/client-app/schedule-turns/schedule-turns.routes')},
                    {path: 'confirm-turn', loadChildren: () => import('app/components/client-app/confirm-turn/confirm-turn.routes')},
                    {path: 'profile', loadChildren: () => import('app/components/client-app/update-client/update-client.routes')},
                ]
            },

            // Dashboards
            {   path: 'dashboards',
                canActivate: [NoClientGuard],
                canActivateChild: [NoClientGuard],
                children: [
                    {path: 'project', loadChildren: () => import('app/components/dashboards/project/project.routes')},
                ]
            },

            // Client turns
            {   path: 'turns',
                canActivate: [ ClientGuard],
                canActivateChild: [ ClientGuard],
                children: [
                    {path: 'my-turns', loadChildren: () => import('app/components/client-app/my-turns/my-turns.routes')},
                ]
            },

            // Turnos
            {   path: 'turnos',
                canActivate: [NoClientGuard, NoProviderGuard],
                canActivateChild: [NoClientGuard, NoProviderGuard],
                children: [
                    {path: 'turnos-asignados', loadChildren: () => import('app/components/turns/assigned-turns/assigned-turns.routes')},
                    {path: 'dar-turnos', loadChildren: () => import('app/components/turns/assign-turns/assign-turns.routes')},
                ]
            },

            // Turnos
            {   path: 'docs',
                canActivate: [NoClientGuard],
                canActivateChild: [NoClientGuard],
                children: [
                    {path: 'guias', loadChildren: () => import('app/components/guides/guides.routes')},
                ]
            },

            // Provider turns
            {   path: 'provider',
                canActivate: [NoClientGuard, ProviderGuard],
                canActivateChild: [NoClientGuard, ProviderGuard],
                children: [
                    {path: 'turnos-propios', loadChildren: () => import('app/components/turns/provider-assigned-turns/provider-assigned-turns.routes')},
                    {path: 'profile', loadChildren: () => import('app/components/client-app/update-client/update-client.routes')},
                    {path: 'schedules', loadChildren: () => import('app/components/schedule/update-provider-schedule/update-provider-schedule.routes')},
                ]
            },

            // Apps
            {   path: 'schedule',
                canActivate: [NoClientGuard, NoProviderGuard],
                canActivateChild: [NoClientGuard, NoProviderGuard],
                children: [
                    {path: 'new-schedule', loadChildren: () => import('app/components/schedule/new-schedule/new-schedule.routes')},
                    {path: 'update-schedule', loadChildren: () => import('app/components/schedule/update-schedule/update-schedule.routes')},
                ]
            },

            // Institution
            {   path: 'institution',
                canActivate: [NoClientGuard, NoProviderGuard],
                canActivateChild: [NoClientGuard, NoProviderGuard],
                children: [
                    {path: 'coordinates', loadChildren: () => import('app/components/institution/select-coordinates/select-coordinates.routes')},
                ]
            },

            // // Institution
            // {   path: 'institution',
            //     canActivate: [NoClientGuard],
            //     canActivateChild: [NoClientGuard],
            //     children: [
            //         {path: 'new-institution', loadChildren: () => import('app/components/institution/new-institution/new-institution.routes')},
            //     ]
            // },

            // Administration
            {   path: 'administration',
                canActivate: [NoClientGuard, NoProviderGuard],
                canActivateChild: [NoClientGuard, NoProviderGuard],
                children: [
                    {path: 'users', loadChildren: () => import('app/components/users/users.routes')},
                ]
            },

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/pages/error/error-404/error-404.routes')},
            {path: '**', redirectTo: '404-not-found'}
        ]
    },
];
