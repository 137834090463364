import { Action } from "@ngrx/store";


export const SET_FUNDERS_CHOSEN = '[Funders] Set Funders Chosen';
export const UNSET_FUNDERS_CHOSEN = '[Funders] Unset Funders Chosen';

export class SetFundersChosenAction implements Action {
    readonly type=SET_FUNDERS_CHOSEN;
    constructor( public funders:any){
    }
}

export class UnsetFundersChosenAction implements Action {
    readonly type = UNSET_FUNDERS_CHOSEN;
}

export type fundersActions= SetFundersChosenAction    |
                                UnsetFundersChosenAction  ;
