import { Action } from "@ngrx/store";

export const SET_CLIENT = '[Client] Set client';
export const UNSET_CLIENT = '[Client] Unset client';

export class SetClientAction implements Action {
    readonly type=SET_CLIENT;
    constructor( public payload:any){
    }
}

export class UnSetClientAction implements Action {
    readonly type=UNSET_CLIENT;
}

export type clientsActions= SetClientAction     |
                            UnSetClientAction                           
