import { User } from 'app/components/users/users.types';
import * as fromUsers from '../actions/users.actions';

export interface UsersState{

    usersList:User[];
    usersLength: any | null; 
    selectedUser:any; 
    roles:any | null

}

const initialState: UsersState={
    usersList:null,
    selectedUser:null,
    roles:null,
    usersLength: null 
};

export function usersReducer (state=initialState,action:fromUsers.usersActions): UsersState{
    switch (action.type) {
        case fromUsers.SET_USERS_LIST:
            return{
                ...state,
                usersList: [... action.payload],
                
            };
        case fromUsers.UNSET_USERS_LIST:
            return {
                ...state,
                usersList: null,
                
            };
        case fromUsers.SET_SELECTED_USER:
            return{
                ...state,
                selectedUser: {...action.payload},
                
            };
        case fromUsers.UNSET_SELECTED_USER:
            return {
                ...state,
                selectedUser: null,
                
            };

        case fromUsers.SET_ROLES:
            return{
                ...state,
                roles: [...action.payload],
                
            };
        case fromUsers.UNSET_ROLES:
            return {
                ...state,
                roles: null,
                
            };
        case fromUsers.SET_USERS_LENGTH:
            return{
                ...state,
                usersLength: action.payload,  
            };
        case fromUsers.UNSET_USERS_LENGTH:
            return {
                ...state,
                usersLength: null,
            };
        
        default:
            return state;
    }
}

