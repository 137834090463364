import * as fromAssignedTurns from '../actions/assigned-turns.actions';

export interface AssignedTurnsState{

    turnsList:any[] | null; 
    length:any | null,
    selectedTurn:any | null; 
    filters:any | null;
}

const initialState: AssignedTurnsState={
    turnsList:null,
    length:null,
    selectedTurn:null,
    filters:{
        status:null,
        client:null,
        provider:null,
        funder:null,
        schedule:null,
        dateFrom:null,
        dateTo:null
    }
};

export function assignedTurnsReducer (state=initialState,action:fromAssignedTurns.assignedTurnsActions): AssignedTurnsState{
    switch (action.type) {
        case fromAssignedTurns.SET_ASSIGNED_TURNS_LIST:
            return{
                ...state,
                turnsList: [... action.payload.list],
                length:action.payload.cant
            };
        case fromAssignedTurns.UNSET_ASSIGNED_TURNS_LIST:
            return {
                ...state,
                turnsList: null,
                
            };
        case fromAssignedTurns.SET_ASSIGNED_SELECTED_TURN:
            return{
                ...state,
                selectedTurn: {...action.payload},
                
            };
        case fromAssignedTurns.UNSET_ASSIGNED_SELECTED_TURN:
            return {
                ...state,
                selectedTurn: null,
                
            };        

        case fromAssignedTurns.SET_ASSIGNED_TURN_FILTERS:
            return{
                ...state,
                filters: {...action.payload},
                
            };
        case fromAssignedTurns.UNSET_ASSIGNED_TURN_FILTERS:
            return {
                ...state,
                filters: {
                    status:null,
                    client:null,
                    provider:null,
                    funder:null,
                    schedule:null,
                    dateFrom:null,
                    dateTo:null
                },
                
            };        
        
        default:
            return state;
    }
}

