import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthService } from 'app/services/auth.service';
import { AppState } from 'app/store/app.reducer';
import { catchError, Observable, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const authService = inject(AuthService);
    const store = inject(Store<AppState>)
    const router = inject(Router)
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.

    // TODO Ver si dejamos esto descomentado o no
    // if ( authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) )
    // {
    //     newReq = req.clone({
    //         headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
    //     });
    // }

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && (error.status === 401))
            {
                // Sign out
                store.select('others').subscribe(data=>{
                    if(data.nuvalidAccess) {
                        authService.singError();
                    } else {
                        router.navigate(['/sign-out']);
                    }
                })
                // // Reload the app
                // location.reload();
            }

            return throwError(error);
        }),
    );
};
