import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PersistenceService } from './services/persistence.service';
import { NavbarWindowsComponent } from 'app/components/navbar-windows/navbar-windows.component'
import moment from 'moment';
import { FuseConfigService } from '@fuse/services/config';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet, NavbarWindowsComponent],
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(
      private persistenceService:PersistenceService,
      private _fuseConfigService:FuseConfigService
      )
    {
    }

    ngOnInit(): void {
        moment.locale('es');
        this.persistenceService.hidratateStore();

      }

      ngOnDestroy(): void {
        this.persistenceService.remove_sesionStorage();

      }
}
