
import * as fromSpecialties from '../actions/specialty.actions';

export interface SpecialtyState{
    specialtiesChosen: any,
}

const initialState: SpecialtyState={
    specialtiesChosen: null,
};

export function specialtyReducer (state = initialState, action: fromSpecialties.specialtiesActions): SpecialtyState{
    switch (action.type) {
        case fromSpecialties.SET_SPECIALTIES_CHOSEN:
            return{
                specialtiesChosen: action.specialties,
            };
        case fromSpecialties.UNSET_SPECIALTIES_CHOSEN:
            return {
                specialtiesChosen: null,
            };
        
        default:
            return state;
    }
}

