
import * as fromNewSchedule from '../actions/step-order.actions';

export interface StepOrderState{
    stepInfo: any;
}

const initialState: StepOrderState={
    stepInfo: null,
};

export function stepOrderReducer (state=initialState,action:fromNewSchedule.stepOrderActions): StepOrderState{
    switch (action.type) {

        case fromNewSchedule.SET_STEP_ORDER:
            return{
                ...state,
                stepInfo: {... action.stepInfo},
            };

        case fromNewSchedule.UNSET_STEP_ORDER:
            return {
                ...state,
                stepInfo: null,
            };
        
        default:
            return state;
    }
}

