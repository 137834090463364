import { Action } from "@ngrx/store";


export const SET_NEW_SCHEDULE = '[NewSchedule] Set Schedule';
export const UNSET_NEW_SCHEDULE = '[NewSchedule] Unset Schedule';
export const SET_EMPLOYEE_ASSOCIATED = '[NewSchedule] Set Doctor Associated';
export const UNSET_EMPLOYEE_ASSOCIATED = '[NewSchedule] Unset Doctor Associated';
export const SET_ATTENTION_DAYS = '[NewSchedule] Set Attention Days';
export const UNSET_ATTENTION_DAYS = '[NewSchedule] Unset Attention Days';
export const SET_DATE_FROM = '[NewSchedule] Set Date From';
export const UNSET_DATE_FROM = '[NewSchedule] Unset Date From';
export const SET_DATE_UNTIL = '[NewSchedule] Set Date Until';
export const UNSET_DATE_UNTIL = '[NewSchedule] Unset Date Until';
export const UNSET_ALL_SCHEDULE = '[NewSchedule] Unset all schedule data stored';
export const SET_TURNS_FRECUENCY = '[NewSchedule] Set Turns Frecuency';
export const UNSET_TURNS_FRECUENCY = '[NewSchedule] Unset Turns Frecuency';

export class SetNewScheduleAction implements Action {
    readonly type=SET_NEW_SCHEDULE;
    constructor( public firstInfo:any){
    }
}

export class UnsetNewScheduleAction implements Action {
    readonly type = UNSET_NEW_SCHEDULE;
}

export class SetEmployeeAssociatedAction implements Action {
    readonly type=SET_EMPLOYEE_ASSOCIATED;
    constructor( public employeeData:any){
    }
}

export class UnsetEmployeeAssociatedAction implements Action {
    readonly type = UNSET_EMPLOYEE_ASSOCIATED;
}

export class SetAttentionDaysAction implements Action {
    readonly type=SET_ATTENTION_DAYS;
    constructor( public attentionDaysData:any){
    }
}

export class UnsetAttentionDaysAction implements Action {
    readonly type = UNSET_ATTENTION_DAYS;
}

export class SetDateFromAction implements Action {
    readonly type=SET_DATE_FROM;
    constructor( public dateFrom: any){
    }
}

export class UnsetDateFromAction implements Action {
    readonly type = UNSET_DATE_FROM;
}

export class SetDateUntilAction implements Action {
    readonly type=SET_DATE_UNTIL;
    constructor( public dateUntil: any){
    }
}

export class UnsetDateUntilAction implements Action {
    readonly type = UNSET_DATE_UNTIL;
}

export class UnsetAllScheduleAction implements Action {
    readonly type = UNSET_ALL_SCHEDULE;
}

export class SetTurnsFrecuencyAction implements Action {
    readonly type=SET_TURNS_FRECUENCY;
    constructor( public turnsFrecuency: any){
    }
}

export class UnsetTurnsFrecuencyAction implements Action {
    readonly type = UNSET_TURNS_FRECUENCY;
}


export type newScheduleActions= SetNewScheduleAction            |
                                UnsetNewScheduleAction          |
                                SetEmployeeAssociatedAction     |
                                UnsetEmployeeAssociatedAction   |
                                SetAttentionDaysAction          |
                                UnsetAttentionDaysAction        |
                                SetDateFromAction               |
                                UnsetDateFromAction             |
                                SetDateUntilAction              |
                                UnsetDateUntilAction            |
                                UnsetAllScheduleAction          |
                                SetTurnsFrecuencyAction         |
                                UnsetTurnsFrecuencyAction       ;
